/* You can add global styles to this file, and also import other style files */
@font-face {
    font-family: 'CustomFont'; /* Custom name for the font */
    src: url('assets/fonts/a34f9d1faa5f3315-s.p.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
  }

  :root {
    --color-bg: whitesmoke ;
     --default-dot-color: #dddddd;
    --dots-colorized: #1b5df7;
    --svg-text: white;
  }

html, body { 
    height: 100%; 
}
body { 
    margin: 0; 
    // font-family: Roboto, "Helvetica Neue", sans-serif; 
    font-family: 'CustomFont', sans-serif!important;
}

.dark-theme .mat-app-background, .dark-theme.mat-app-background {
    background-color: #000000!important;
    color: rgb(204, 204, 204)!important;
}
.mat-app-background, .mat-app-background {
    background-color: whitesmoke!important;
    color: #2c313a;
}
.scroll-svg{
    .stroke1, .stroke2{
      stroke: rgba(0,0,0,1)!important;
    }   
}
//       :root {
//     --color-bg: black;
//     /* --default-dot-color: #dddddd;;
//     --dots-colorized: #7099fa; */
//     --default-dot-color: #212121;
//     --dots-colorized: #223bff;
//   }
.radial-gradient {   
    // background: linear-gradient(to bottom, transparent, #0000007a 100%), linear-gradient(to bottom, transparent, #0000007a 50%);
}
.dark-theme {
    .security-section{
        .security-container{
            & > div {
                &:before {
                    background-image: linear-gradient(to top,  rgba(38,38,38,0), #262626)!important;
                }
            }
        }
      
        mat-icon {
            font-size: 48px;
            color: #cccccc!important; /* Example color for the icon */
        }
        h3{
            color: white!important;
        }
    }
    #ci-cd-timeline:before {
        box-shadow: 0px 0px 60px 1px #03a9f4;
    }
    .timeline-icon {
        box-shadow: -2px 5px 80px 8px #03a9f4;
    }
    .mat-card{
        color:#cccccc !important
    }
    .phone div:first-child {
        background: #00000073!important;
      }
    .footer-container {
       a, i, img{ color: #cccccc !important;
       }
    }
    .custom-card{
        mat-card-title{
            color: white!important;
        }
    }
    #timeline .timeline-item .timeline-icon {
        box-shadow: -2px 5px 100px 5px #03a9f4;
    }
    #timeline .timeline-content {
        box-shadow: none!important;
    }
    .card-container{
        @media screen and (max-width: 880px) {
            border-right: unset!important;
            border-top: 1px solid #a3a3a33b!important;
        }
        border-right: 1px solid  #a3a3a33b!important;
        border-bottom: 1px solid  #a3a3a33b!important;
    }
    .dashed-box{
        border: 1px dashed rgba(211, 211, 211, 0.231)!important;
    }

    .scroll-svg{
        .stroke1, .stroke2{
            stroke: rgba(255,255,255,1)!important;
        }   
    }
    .dots-container{
        --color-bg: black!important;
    }

    .landing{
        &::after  {
            background: linear-gradient(transparent, black)!important;
        }
    }
    section:nth-child(n+5)::before {
    background: transparent !important;
    }

    .app-container section:nth-of-type(-n+2)::before {
        background: transparent !important;
    }

    .corner-icon{
        background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="rgb(222, 222, 222)"><path stroke-linecap="round" stroke-linejoin="round" d="M12 6v12m6-6H6"></path></svg>') no-repeat center!important;
    }

    .spacer {
        background-image: url('assets/svg/spacer-dark.svg')!important;
    }
    .spacer-thin {
        background-image: url('assets/svg/spacer-thin-dark.svg')!important;
    }

    .fade-container::before {
        background: linear-gradient(to right, #090909, transparent)!important;
    }
      
    .fade-container::after {
        background: linear-gradient(to left, #090909, transparent)!important;
    }

    .action-text {

        background: linear-gradient(to bottom, #65e4ff, #00208d)!important;
        background-clip: text!important;
        -webkit-background-clip: text!important;
    }

    .view-work-button {
        
        color: #d4d4d4!important;
        &:hover{
            background-color: rgba(71, 71, 71, 0.333)!important;
            color: white!important;
        }
    }
    
    .scroll-nav{
        background-color: rgb(38, 42, 54, .5)!important;
        border-color: hsla(0, 0%, 95%, .1)!important;
        border-style: solid!important;
    }
    nav{
        color: rgb(212 212 212)!important;
    }
}


.ecom-api-summary{

    counter-reset: item;
    list-style-type: none;
    
    li {
        margin: 10px 0;
        padding-left: 35px;
        position: relative;
        text-align: left;
            &:before {
            content: counter(item);
            counter-increment: item;
            background: rgb(37, 150, 190);
            color: white;
            border-radius: 50%;
            display: inline-block;
            width: 25px;
            height: 25px;
            line-height: 25px;
            text-align: center;
            position: absolute;
            left: 0;
            top: 0;
        }
    }
}


section:last-of-type {
    margin-top: 0;
    padding-top: 0;
}

section {
    //border:1px solid grey;
    min-height: 100vh;
    width: 100%;
    // margin-bottom: 300px;
    overflow: hidden;
    position: relative;
}
.app-container {
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;
    scroll-behavior: smooth;
    z-index: 1;
}
.app-wrapper{
    overflow: hidden;
    position: relative;
}
// .particles-js-canvas-el {
//     position: fixed;
//     top: 0;
//     z-index: -1;
// }

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';
