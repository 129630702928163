
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

/* For use in src/lib/core/theming/_palette.scss */
/* For use in src/lib/core/theming/_palette.scss */
/* For use in src/lib/core/theming/_palette.scss */
$md-ndblue: (
    50 : #effafc,
    100 : #d6f3f8,
    200 : #bbebf4,
    300 : #9fe3ef,
    400 : #8bddeb,
    500 : #76d7e8,
    600 : #6ed3e5,
    700 : #63cde2,
    800 : #59c7de,
    900 : #46bed8,
    A100 : #ffffff,
    A200 : #fbfeff,
    A400 : #c8f4ff,
    A700 : #aef0ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);





// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$portfolio-primary: mat-palette($md-ndblue);
$portfolio-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$portfolio-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$portfolio-theme: mat-light-theme((
  color: (
    primary: $portfolio-primary,
    accent: $portfolio-accent,
    warn: $portfolio-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($portfolio-theme);

.dark-theme {
  $alt-theme: mat-dark-theme($portfolio-primary, $portfolio-accent);
  color: $light-primary-text;
  @include angular-material-theme($alt-theme);
}